const host= window.location.hostname
if(host === 'localhost' || host === '127.0.0.1'){
    backend_url_api = 'http://localhost:4000/api'
    backend_url = 'http://localhost:4000'
    REACT_APP_FACEBOOK_APP_ID = '1012980039736848'
    REACT_APP_FB_CLIENT_SECRET = '98ce65b92cbe478b01928a6e908954e3'
}else{
    backend_url_api = 'https://zm.netpluscollege.ca/api'
    backend_url = 'https://zm.netpluscollege.ca'
    REACT_APP_FACEBOOK_APP_ID = '1012980039736848'
    REACT_APP_FB_CLIENT_SECRET = '98ce65b92cbe478b01928a6e908954e3'
}
// backend_url_api = 'https://api.netpluscollege.com/api'
// backend_url = 'https://api.netpluscollege.com'
module.exports = {
    globals: {
        'backend_url_api': backend_url_api,
        'backend_url': backend_url,
        'REACT_APP_FACEBOOK_APP_ID': REACT_APP_FACEBOOK_APP_ID,
        'REACT_APP_FB_CLIENT_SECRET': REACT_APP_FB_CLIENT_SECRET,
        'tiny_api_key': '3djck8249ppha7ajlw5u4i4sslhhbefzwbhbhys4v3fy1t1q',
        'email':'zakria.khan@hhdsoftware.org'

    }
};

