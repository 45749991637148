import React from 'react'
import logo_nobg from '../assets/netplus/logo-final_wt.webp';
import {Link} from 'react-router-dom'
import { Facebook, Instagram, Linkedin, Youtube } from 'react-bootstrap-icons';

const Footer = () => {
  return (
        <>
            <footer>
                <div className="bg-dark-gray" >
                    <div className='container-np py-4'>
                        <div className='row pt-5 text-white gx-5'>
                            <div className='col-lg-4 col-md-12'>
                                <img src={logo_nobg} className='img-fluid' width={220} alt='logo' />
                                {/* <hr className='border-np-success2'/> */}
                                <p className='py-4'>
                                    NetPLUS College of Information Technology, a registered training college in Toronto, Ontario, delivers nationally accredited courses to students and corporate enterprises worldwide. Providing a smooth pathway to your bright future.
                                </p>
                            </div>
                        {/* </div>
                        <div className='row text-white gx-5'> */}
                            <div className='col-lg-3 col-md-4'>
                                <div className='text-uppercase text-work-sans fs-5'>Courses</div>
                                <hr className='border-np-success2 my-3'/>
                                <ul className='list-unstyled'>
                                    <li><Link to='/full-time/higher-diploma-in-software-engineering' className='text-decoration-none text-light lh-lg'>Software Development</Link></li>
                                    <li><Link to='/full-time/diploma-in-accounting' className='text-decoration-none text-light lh-lg'>Diploma in Accounting</Link></li>
                                    <li><Link to='/full-time/diploma-in-networking' className='text-decoration-none text-light lh-lg'>Diploma In Networking</Link></li>
                                    <li><Link to='/part-time-courses/cisco' className='text-decoration-none text-light lh-lg'>Cisco</Link></li>
                                    <li><Link to='/part-time-courses/linux' className='text-decoration-none text-light lh-lg'>Linux</Link></li>
                                </ul>
                            </div>
                            <div className='col-lg-2 col-md-4'>
                                <div className='text-uppercase text-work-sans fs-5 text-nowrap'>Quick Links</div>
                                <hr className='border-np-success2 my-3'/>
                                <ul className='list-unstyled'>
                                    <li><Link to='/about-us' className='text-decoration-none text-light lh-lg'>About us</Link></li>
                                    <li><Link to='/kpi' className='text-decoration-none text-light lh-lg'>KPI</Link></li>
                                    {/* <li><Link to='/international-students' className='text-decoration-none text-light lh-lg'>International students</Link></li> */}
                                    <li><Link to='/cojg' className='text-decoration-none text-light lh-lg'>COJG</Link></li>
                                    <li><Link to='/better-jobs-ontario' className='text-decoration-none text-light lh-lg'>Better Jobs Ontario</Link></li>
                                    <li><Link to='/policies' className='text-decoration-none text-light lh-lg'>Policies</Link></li>
                                    {/* <li><Link to='/' className='text-decoration-none text-light lh-lg'>Testimonial</Link></li> */}
                                </ul>
                            </div>
                            <div className='col-lg-3 col-md-4'>
                                <div className='text-uppercase text-work-sans fs-5'>Contact US</div>
                                <hr className='border-np-success2 my-3'/>
                                <ul className='list-unstyled'>
                                    <li>Email:<a className='text-decoration-none text-light lh-lg' href='mailto:contact@netpluscollege.com'> contact@netpluscollege.com</a> | <a className='text-decoration-none text-light lh-lg' href='mailto:international@netpluscollege.com'> international@netpluscollege.com</a></li>
                                    <li><a href='tel:+14162920003' className='text-decoration-none text-light lh-lg'>Phone: 416-292-0003</a></li>
                                    {/* <li><span to='/' className='text-decoration-none text-light lh-lg'>Fax: +416-292-9117</span></li> */}
                                    <li><a href="https://goo.gl/maps/n2ai816KDT2x9xhp9" target="_blank" className="text-decoration-none text-np-success"><span className=' text-light lh-lg fw-bold'>1891 Kennedy Road, Unit #3 Toronto, Ontario, M1P, 2L9</span></a></li>
                                    {/* <li><span className=' text-light lh-lg fw-bold'></span></li> */}
                                    
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='py-3 bg-light-gray'>
                    <div className='container-np'>
                        <div className='row text-white'>
                            <div className='col-md-6'>
                                <div className='float-md-start text-center'>
                                    <p className='text-light'>Copyright © All Right Reserved 2023 | Developed by <a href="https://zmcommunication.org/" className='text-white text-decoration-none' target="_blank">zmcommunication</a></p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                    <div className="social-footer mx-auto float-md-end text-center">
                                        <a href="https://www.facebook.com/NetPLUSITCollege/" target="_blank" className="text-decoration-none text-white" rel="noreferrer" aria-label="Facebook">
                                            <Facebook size={26} className="mx-2"/>
                                        </a>
                                        {/* instagram */}
                                        <a href="https://www.instagram.com/netpluscollege/" target="_blank" className="text-decoration-none text-white" rel="noreferrer" aria-label="Instagram">
                                            <Instagram size={26} className="mx-2"/>
                                        </a>
                                        {/* twitter */}
                                        {/* <a href="https://twitter.com/netpluscollege" target="_blank" className="text-decoration-none text-white">
                                        <Twitter size={26} className="mx-2"/>
                                        </a> */}
                                        {/* linkedin */}
                                        <a href="https://www.linkedin.com/company/netplus-college-of-information-technology/" target="_blank" className="text-decoration-none text-white" rel="noreferrer" aria-label="Linkedin">
                                            <Linkedin size={26} className="mx-2"/>
                                        </a>
                                        {/* youtube */}
                                        <a href="https://www.youtube.com/channel/UCNnzrFgdgCDADrQTJ9yMd7w" target="_blank" className="text-decoration-none text-white" rel="noreferrer" aria-label="Youtube">
                                            <Youtube size={26} className="mx-2"/>
                                        </a>
                                        
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
  )
}

export default Footer
