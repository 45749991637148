import React from 'react'
import {Routes, Route} from 'react-router-dom';
// import Login from './Login';
// import ForgotPassword from './ForgotPassword';
// import PasswordReset from './PasswordReset';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import 'src/crm/assets/css/crm.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// const NotFound = React.lazy(() => import('../website/404'));



const CRMRouter = () => {
  return (
    <React.StrictMode>

            {/* <Routes> */}
                {/* <Route path="/crm/" element={<Dashboard />} /> */}
                {/* <Route path="crm/login" element={<Login />} />
                <Route path="crm/forgot-password" element={<ForgotPassword />} />
                <Route path="crm/password-reset/:userId/:token" element={<PasswordReset />} />
                <Route path="crm/*" element={<DefaultLayout />} /> */}
                {/* <Route path="*" element={<NotFound />} /> */}

            {/* </Routes> */}
            {/* <BasicToast/> */}
            <ToastContainer 
              position="bottom-left"
              autoClose={5000}
            />

    </React.StrictMode>
  )
}

export default CRMRouter