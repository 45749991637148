import React from 'react'
// import page404Img from '../assets/images/404.jpg'
import NavMenu from './NavMenu';
import Footer from './Footer';

const Page404 = () => {
  return (
    <>
        <NavMenu/>
            {/* <img src={page404Img} alt="404" className='img-fluid'  style={{height:"100vh", width:"100%"}}/> */}
            <div className='container-np py-5 my-md-5'>
                <div className="text-center" style={{fontWeight:"600", fontSize:"100px"}}>
                    404
                    <div className='text-np-success mt-3 fs-np-30'>Sorry! This page not found. It might be deleted or its name changed.</div>
                </div>
            </div>
        <Footer/>
    </>
  )
}

export default Page404