import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import WebsiteRouter from './website/Router';
import CRMRouter from './crm/Router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css';
import config from './globals';
import Page404 from './website/404'

const App = () => {

    // get gclid from url and save it in session storage
    const urlParams = new URLSearchParams(window.location.search);
    
    const gclid = urlParams.get('gclid');
    const fbclid = urlParams.get('fbclid');
    if(gclid){
        sessionStorage.setItem('gclid', gclid);
    }

    if(fbclid){
        sessionStorage.setItem('fbclid', fbclid);
    }


    const baseURL = config.globals.backend_url_api;

    return (
        <React.StrictMode>
            <Router>
            <CRMRouter/>
            <WebsiteRouter baseURL={baseURL}/>
            {/* <Routes>
                <Route path="*" element={<Page404/>}  />

            </Routes> */}
            
            </Router>
        </React.StrictMode>
    )
}

export default App